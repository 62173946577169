import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ProgressIndicator from './ProgressIndicator';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import { Flipper, Flipped } from 'react-flip-toolkit';
import '../css/flashing.css'
import { placementIsShown } from '../common/helpers';
import { MdStars } from "react-icons/md";
import * as constants from "../common/constants";
import { renderPoints } from '../common/helpers';
import { withRouter } from 'react-router-dom';

const styles = theme => ({
  table: {
    minWidth: 700
  },
  tableHeader: {
    position: "sticky",
    backgroundColor: "#F8F8F8",
    top: 0,
    paddingTop: 75,
    paddingBottom: 20,
    zIndex: 10,
    minWidth: '100px',
    textAlign: 'center',
    padding: '4px 10px 4px 10px',
    color: '#000'
  },
  topLeftCell: {
    position: "sticky",
    backgroundColor: "#F8F8F8",
    paddingTop: 23,
    paddingBottom: 20,
    paddingLeft: 13,
    top: 0,
    left: 0,
    zIndex: 11,
    whiteSpace: 'nowrap'
  },
  nameCell: {
    position: "sticky",
    backgroundColor: "#F8F8F8",
    left: 0,
    zIndex: 9,
    maxWidth: 130,
    minWidth: 130,
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    width: 130,
    padding: '4px 0px 4px 9px',
    whiteSpace: 'nowrap'
  },
  pointCell: {
    backgroundColor: "#F8F8F8",
    width: '0.8rem',
    padding: '4px 0px 4px 0px',
  },
  rightIcon: {
    marginLeft: '3px',
    color: 'lightgray',
    fontSize: '1.5em'
  },
  eventEditIcon: {
    marginLeft: '3px',
    color: 'lightgray',
    fontSize: '13px',
    verticalAlign: 'middle'
  },
  button: {
    minWidth: '100px',
    color: '#FFF',
    backgroundColor: '#509CF5',
    '&:hover': {
      backgroundColor: '#0d74eb',
      color: '#FFF',
    },
    '&:active': {
      backgroundColor: '#0d74eb',
    }
  },
  buttonColors: {
    border: '0px solid #509CF5',
    backgroundColor: '#509CF5',
    '&:hover': {
      backgroundColor: '#0d74eb',
      color: '#FFF',
    },
    '&:active': {
      backgroundColor: '#0d74eb',
    }
  },
  buttonAddPlayer: {
    fontSize: '0.9em',
    color: '#FFF',
    margin: 'auto',
  }
});

class ScoreTable extends React.Component {

  openKeypadDialog(player, point, points, isEdit, isEditingClosestTo) {

    let playerId = null;
    let playerName = null;
    let teamId = null;
    if (player !== null) {
      playerId = player.id;
      playerName = player.name;
      teamId = player.teamId;
    }

    this.props.openKeypadDialog(teamId, playerId, playerName, point, points, isEdit, isEditingClosestTo);
  };

  getTableHeaderContent(point, classes, player) {

    if (point.calculationCategory === constants.calculationCategories.closest.value) {
      return <div style={{ cursor: 'pointer' }} onClick={() => this.openKeypadDialog(player, point, point.closestTo, true, true)}>
        <span>{point.eventName} {point.isDividingEvent && <MdStars style={{ position: 'absolute', color: '#f4c20d', marginLeft: 5, fontSize: '14px' }} />}
          <br /><span style={{ fontSize: '11px' }}>närmast {point.closestTo}p </span></span>
        <EditIcon className={classes.eventEditIcon} style={{ position: 'absolute', marginLeft: 0, fontSize: '14px' }} /></div>
    }
    else if (point.isDividingEvent === true) {
      return <div>{point.eventName}
        <MdStars style={{ position: 'absolute', color: '#f4c20d', marginLeft: 5, fontSize: '14px' }} /><br />&nbsp;</div>;
    }
    else {
      return <span>{point.eventName}<br />&nbsp;</span>
    }
  }

  render() {

    const { classes, team, gameId } = this.props;
    if (team !== null) {
      return (
        <Flipper flipKey={team.players} spring="gentle">
          <Table key={team.id} className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.topLeftCell}>
                  <div style={{ position: 'absolute' }}>
                     <Button className={classes.buttonColors + ' ' + classes.buttonAddPlayer} onClick={() => this.props.history.push('/admin/' + gameId + '/edit')}>Redigera</Button>
                  </div>
                </TableCell>
                {team.players.length > 0 && team.players[0].points.map(point => {
                  return (
                    <TableCell key={point.id} className={classes.tableHeader}>
                      {this.getTableHeaderContent(point, classes, team.players[0])}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {team.players.map((player, index) => {
                return (
                  <Flipped key={player.id} flipId={player.id}>
                    <TableRow>
                      <TableCell className={classes.nameCell} component="th" scope="row">
                        <div style={{ display: 'inline-block', backgroundColor: placementIsShown(team.players, index) ? '#ff5960' : '#F8F8F8', borderRadius: '50%', height: '30px', width: '30px', lineHeight: '30px', textAlign: 'center' }}>
                          <span style={{ fontSize: '1.2em', color: '#F8F8F8' }}>{(player.placement && placementIsShown(team.players, index)) ? player.placement : <span>&nbsp;</span>}</span>
                        </div>
                        <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '6px', lineHeight: '14px' }}>
                          <span style={{ fontSize: '1em' }}>
                            {player.name}
                            <br />
                          </span>
                          <span style={{ fontSize: '0.7em' }}>
                            Placeringspoäng: {player.totalPlacementpoints}
                          </span>
                        </div>
                      </TableCell>
                      {player.points.map(points => {
                        return (
                          <TableCell className={classes.pointCell} key={points.id} style={{ textAlign: 'center' }}>
                            {points.points === null ?
                              <Button
                                size="small"
                                className={classes.button}
                                onClick={() => this.openKeypadDialog(player, points, points.points, false, false)}>
                                Ange poäng
                              </Button>
                              :
                              <div
                                style={{ display: 'inline-block', borderRadius: '5px', height: '100%' }}
                                className={this.props.flashingPointId === points.id ? 'flash' : 'noFlash'}>
                                <Button color="primary" style={{ whiteSpace: 'nowrap', color: '#000', backgroundColor: 'transparent', maxWidth: '103px' }}
                                  onClick={() => this.openKeypadDialog(player, points, points.points, true, false)}>
                                  &nbsp;&nbsp;{points.placementpoints}<span style={{ textTransform: 'none' }}>&nbsp;/&nbsp;{renderPoints(points)}</span>
                                  <EditIcon className={classes.rightIcon} />
                                </Button>
                              </div>
                            }
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  </Flipped>
                );
              })}
            </TableBody>
          </Table>
        </Flipper>
      )
    }
    return (<ProgressIndicator />)
  }
}

ScoreTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(ScoreTable));
