
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import NewPlayerInput from '../components/NewPlayerInput';
import IconButton from '@material-ui/core/IconButton';
import { TiTimes } from 'react-icons/ti';
import { BiMove } from 'react-icons/bi';
import { MdEdit } from 'react-icons/md';

const styles = theme => ({
  root: {
    flexgrow: 1,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20
  },
  button: {
    fontSize: '0.9em',
    minHeight: 80,
    color: 'white',
    margin: 'auto',
    width: '100%',
  },
  buttonResumeGoBack: {
    backgroundColor: '#00ccff',
    '&:hover': {
      backgroundColor: '#00c0f0',
    },
    '&:active': {
      backgroundColor: '#00c0f0',
    }
  },
  buttonCreateTeam: {
    backgroundColor: '#02AE01',
    '&:hover': {
      backgroundColor: '#028901',
    },
    '&:active': {
      backgroundColor: '#028901',
    }
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  TypographyAddTeam: {
    fontSize: 18,
    color: '#039be5'
  },
  gridItem: {
    marginTop: 5
  },
  icon: {
    marginLeft: 5
  },
  cardTeam: {
    width: '100%',
    border: '2px solid #AAAAAA',
    backgroundColor: '#FFF'
  },
  TypographyTeamName: {
    fontSize: 24,
  },
  chip: {
    marginRight: theme.spacing.unit / 2,
    marginTop: theme.spacing.unit / 2,
    backgroundColor: '#e6e6e6'
  },
  buttonColors: {
    border: '0px solid #509CF5',
    backgroundColor: '#509CF5',
    '&:hover': {
      backgroundColor: '#0d74eb',
      color: '#FFF',
    },
    '&:active': {
      backgroundColor: '#0d74eb',
    }
  },
  buttonAddPlayer: {
    fontSize: '0.9em',
    color: '#FFF',
    margin: 'auto',
    marginLeft: '8px', marginBottom: '3px'
  },
});

class TeamsCard extends React.Component {

  handleDeletePlayer = (playerId, teamId) => {
    this.props.handleDeletePlayer(playerId, teamId);
  }

  showDeleteTeamDialog = (index) => {
    this.props.showDeleteTeamDialog(index);
  }

  showMovePlayerDialog = (player) => {
    this.props.showMovePlayerDialog(player);
  }

  showEditPlayerDialog = (player) => {
    this.props.showEditPlayerDialog(player);
  }

  handleOpenEditTeamDialog = (team) => {
    this.props.showEditTeamDialog(team);
  }

  render() {
    const { classes, team, index } = this.props;

    return (

      <Grid container spacing={24}>
        <Grid item xs={12} sm={12} className={classes.gridItem}>
          <Card className={classes.cardTeam}>
            <CardContent>
              <Typography style={{ display: 'inline-block' }} variant="subtitle1" color="primary" gutterBottom className={classes.TypographyTeamName}>
                {team.name}
                <MdEdit onClick={() => this.handleOpenEditTeamDialog(team)} size={20} style={{ color: "grey", marginLeft: 5, verticalAlign: "initial", cursor: "pointer" }} />
              </Typography>

              <IconButton align="right" style={{ float: 'right' }} aria-label="Refresh" onClick={() => this.showDeleteTeamDialog(index)}>
                <TiTimes style={{ fontSize: 30, color: '#989898' }} />
              </IconButton>

              <br/>
              {team.players.map(player => {
                let avatar = null;
                return (
                  <Chip
                    key={player.id}
                    avatar={avatar}
                    label={<span>
                    <BiMove onClick={() => this.showMovePlayerDialog(player)} size={20} style={{ color: "grey", marginRight: 5, verticalAlign: "middle", cursor: "pointer" }} />
                    <MdEdit onClick={() => this.showEditPlayerDialog(player)} size={20} style={{ color: "grey", marginRight: 5, verticalAlign: "middle", cursor: "pointer" }} />
                    <span style={{marginRight: 3}}>{player.name}</span>
                    </span>}
                    onDelete={() => this.handleDeletePlayer(player.id, team.id)}
                    className={classes.chip}
                  />
                );
              })}

            </CardContent>
            <CardActions>
              <NewPlayerInput
                onAddPlayer={this.props.handleAddPlayer}
                teamIndex={index}
              />
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(TeamsCard);