import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import * as Routes from '../constants/routes';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ActiveGamesDialog from '../components/ActiveGamesDialog';
import firebase, { db } from '../firebase/firebase';
import Zoom from '@material-ui/core/Zoom';
import NanoId from 'nanoid';
import CreateItemDialog from '../components/CreateItemDialog';
import EditPlayerDialog from '../components/CreateItemDialog';
import EditTeamDialog from '../components/CreateItemDialog';
import NewPlayerInput from '../components/NewPlayerInput';
import OtherInfoInput from '../components/OtherInfoInput';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { AuthContext } from '../firebase/authContext';
import {
  IoIosArrowForward,
  IoIosArrowDropleft,
  IoIosCheckmarkCircleOutline
} from 'react-icons/io';
import { MdMoreHoriz, MdGroupAdd } from 'react-icons/md';
import { TiTimes } from 'react-icons/ti';
import AlertDialog from '../components/AlertDialog';
import ConfirmDialog from '../components/ConfirmDialog';
import { find } from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import '../css/custom.css'
import * as constants from "../common/constants";
import { MdEdit } from 'react-icons/md';

const styles = theme => ({
  root: {
    flexgrow: 1,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20
  },
  buttonColors: {
    border: '0px solid #509CF5',
    backgroundColor: '#509CF5',
    '&:hover': {
      backgroundColor: '#0d74eb',
      color: '#FFF',
    },
    '&:active': {
      backgroundColor: '#0d74eb',
    }
  },
  buttonAddPlayer: {
    fontSize: '0.9em',
    color: '#FFF',
    /*  margin: 'auto', */
    height: 54
  },
  button: {
    fontSize: '0.9em',
    color: '#FFF',
    margin: 'auto',
    minHeight: '160px',
    width: '100%',
    display: 'inline',
  },
  buttonText: {
    color: '#FFF'
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  TypographyAddTeam: {
    fontSize: 18,
    color: '#039be5'
  },
  gridItem: {
    marginTop: 5
  },
  icon: {
    marginLeft: 5
  },
  cardTeam: {
    width: '100%',
    border: '2px solid #509CF5',
    backgroundColor: '#FFF'
  },
  TypographyTeamName: {
    fontSize: 24,
  },
  chip: {
    marginRight: theme.spacing.unit / 2,
    marginTop: theme.spacing.unit / 2,
    backgroundColor: '#e6e6e6',
  },
  grow: {
    flexGrow: 1,
  },
  textField: {
    maxWidth: 300,
  }
});

class Play extends React.Component {

  constructor() {
    super()

    this.toggleAlertDialog = this.toggleAlertDialog.bind(this);
    this.handleDeleteTeam = this.handleDeleteTeam.bind(this);
    this.toggleDeleteTeamDialog = this.toggleDeleteTeamDialog.bind(this);
    this.handleStartGame = this.handleStartGame.bind(this);
    this.handleAddPlayer = this.handleAddPlayer.bind(this);
    this.handleSelectGame = this.handleSelectGame.bind(this);
    this.handleCloseActiveGamesDialog = this.handleCloseActiveGamesDialog.bind(this);
    this.handleTeamNameChange = this.handleTeamNameChange.bind(this);
    this.handleAddTeam = this.handleAddTeam.bind(this);
    this.handleShowSelectEvents = this.handleShowSelectEvents.bind(this);
    this.handleOpenActiveGamesDialog = this.handleOpenActiveGamesDialog.bind(this);
    this.handleOpenTeamDialog = this.handleOpenTeamDialog.bind(this);

    this.handleOpenEditPlayerDialog = this.handleOpenEditPlayerDialog.bind(this);
    this.handlePlayerNameChange = this.handlePlayerNameChange.bind(this);
    this.handleUpdatePlayer = this.handleUpdatePlayer.bind(this);
    this.handleCloseEditPlayerDialog = this.handleCloseEditPlayerDialog.bind(this);

    this.handleOpenEditTeamDialog = this.handleOpenEditTeamDialog.bind(this);
    this.handleTeamNameUpdateChange = this.handleTeamNameUpdateChange.bind(this);
    this.handleCloseEditTeamDialog = this.handleCloseEditTeamDialog.bind(this);
    this.handleUpdateTeam = this.handleUpdateTeam.bind(this);
    
    this.handleOtherInfoChange = this.handleOtherInfoChange.bind(this);

    this.state = {
      activeGamesDialogIsOpen: false,
      games: [],
      zoomIn: false,
      newTeam: {
        name: ""
      },
      addTeamDialogStatus: false,
      editPlayerDialogStatus: false,
      editTeamDialogStatus: false,
      playerInEdit: null,
      teamInEdit: null,
      teams: [],
      selectedTeamIndex: null,
      points: [],
      checkedPoints: [],
      showSelectEvents: false,
      showStartDialog: true,
      showTeamItems: true,
      alertDialogIsOpen: false,
      deleteTeamDialogIsOpen: false,
      dialogBody: "",
      otherInfo: ""
    }
  }

  componentWillMount() {
    document.body.className += ' bgTexture';
  }

  componentDidMount() {

    var points = [];

    let customerId = this.context.customer.id;

    db.collection("customers").doc(customerId).collection('events').get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        var pointObj = {
          id: null,
          eventId: doc.id,
          eventName: doc.data().name,
          points: null,
          placementpoints: null,
          isDividingEvent: doc.data().isDividingEvent,
          calculationCategory: doc.data().calculationCategory,
          calculationFormat: doc.data().calculationFormat,
          unit: doc.data().unit,
          placementCalculation: doc.data().placementCalculation,
          closestTo: doc.data().closestTo,
          diffToClosest: doc.data().closestTo,
          roundPoints: doc.data().roundPoints
        }

        points.push(pointObj);
      });
    });

    this.setState({
      points: points,
      zoomIn: true
    });
  }

  toggleAlertDialog(dialogBody) {
    this.setState({
      alertDialogIsOpen: !this.state.alertDialogIsOpen,
      dialogBody: dialogBody
    });
  }

  toggleDeleteTeamDialog() {
    this.setState({ deleteTeamDialogIsOpen: !this.state.deleteTeamDialogIsOpen });
  }

  handleDeleteTeam = () => {
    var teams = Object.assign([], this.state.teams);
    teams.splice(this.state.selectedTeamIndex, 1);
    this.setState({
      teams: teams,
      deleteTeamDialogIsOpen: false
    });
  }

  handleToggleEvents(point) {
    const { checkedPoints } = this.state;
    const currentIndex = checkedPoints.indexOf(point);
    const newChecked = [...checkedPoints];

    if (currentIndex === -1) {
      newChecked.push(point);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checkedPoints: newChecked,
    });
  };


  handleStartGame() {

    var current = this;

    let customer = this.context.customer;
    var teams = Object.assign([], this.state.teams);

    teams.forEach(team => {

      var points = JSON.parse(JSON.stringify(this.state.checkedPoints));

      points.forEach(point => {
        point.id = NanoId();
      });
      team.points = points;

      team.players.forEach(player => {
        var points = JSON.parse(JSON.stringify(this.state.checkedPoints));
        points.forEach(point => {
          point.id = NanoId();
        });
        player.points = points;
        player.teamId = team.id;
      });
    });

    db.collection("customers").doc(customer.id).collection('games').add({
      updated: null,
      created: firebase.firestore.Timestamp.fromDate(new Date()),
      isActive: true,
      sortingEnabled: customer.sortingDefaultMode,
      otherInfo: this.state.otherInfo,
      teams: this.state.teams
    })
      .then(function (docRef) {
        current.props.history.push(Routes.PLAY + "/" + docRef.id);
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      });
  };

  handleCloseTeamDialog = () => {
    let newTeam = Object.assign({}, this.state.newTeam);
    newTeam.name = "";
    this.setState({ newTeam });
    this.setState({ addTeamDialogStatus: false });
  };

  handleCloseEditPlayerDialog = () => {
    this.setState({ editPlayerDialogStatus: false });
  };

  handleCloseEditTeamDialog = () => {
    this.setState({ editTeamDialogStatus: false });
  };
  

  handleDeletePlayer = (player) => {

    let teams = Object.assign([], this.state.teams);

    teams.forEach(team => {

      var playerToDelete = team.players.find(x => x.id === player.id);

      let filteredPlayers = team.players.filter(player => player !== playerToDelete);
      team.players = filteredPlayers;

      this.setState({ teams: teams });
    });
  }

  showDeleteTeamDialog = (index) => {
    this.setState({
      deleteTeamDialogIsOpen: true,
      selectedTeamIndex: index
    });
  }

  handleTeamNameChange(value) {
    let newTeam = Object.assign({}, this.state.newTeam);
    newTeam.name = value;
    this.setState({ newTeam });
  }

  handlePlayerNameChange(value) {
    let player = Object.assign({}, this.state.playerInEdit);
    player.name = value;
    this.setState({ playerInEdit: player });
  }

  handleTeamNameUpdateChange(value) {
    let team = Object.assign({}, this.state.teamInEdit);
    team.name = value;
    this.setState({ teamInEdit: team });
  }

  handleAddTeam() {
    let newTeam = Object.assign({}, this.state.newTeam);
    newTeam.created = firebase.firestore.Timestamp.fromDate(new Date());
    newTeam.id = NanoId();
    newTeam.players = [];
    newTeam.totalPlacementpoints = null;
    newTeam.totalPoints = null;
    newTeam.placement = null;

    this.setState({
      teams: [...this.state.teams, newTeam],
      addTeamDialogStatus: false
    });
  };

  handleUpdatePlayer() {

    let teams = Object.assign([], this.state.teams);
    //Itererar lagen och försöker hitta spelaren som ska uppdateras.
    teams.map(team => {
      var playerToUpdate = team.players.find(x => x.id === this.state.playerInEdit.id);
      if (typeof playerToUpdate !== "undefined") {
        playerToUpdate.name = this.state.playerInEdit.name;
      }
      return team;
    });

    this.setState({
      teams: teams,
      playerInEdit: null,
      editPlayerDialogStatus: false
    });
  };

  handleUpdateTeam() {

    let teams = Object.assign([], this.state.teams);
    //Itererar lagen och försöker hitta spelaren som ska uppdateras.
    teams.map(team => {
      if (team.id === this.state.teamInEdit.id) {
        team.name = this.state.teamInEdit.name;
      }
      return team;
    });

    this.setState({
      teams: teams,
      teamInEdit: null,
      editTeamDialogStatus: false
    });

  }

  handleOtherInfoChange(value) { 
    this.setState({
      otherInfo: value
    });
  }

  handleAddPlayer(playerName, teamIndex) {

    var newPlayer = {
      id: NanoId(),
      name: playerName,
      totalPlacementpoints: null,
      totalPoints: null,
      placement: null,
      created: firebase.firestore.Timestamp.fromDate(new Date())
    };

    var teamsArr = Object.assign([], this.state.teams);

    teamsArr[teamIndex].players.push(newPlayer);
    this.setState({
      teams: teamsArr
    });
  }

  handleOpenTeamDialog() {
    this.setState({ addTeamDialogStatus: true });
  };

  handleOpenEditPlayerDialog(player) {
    this.setState({
      editPlayerDialogStatus: true,
      playerInEdit: player
    });
  };

  handleOpenEditTeamDialog(team) {
    this.setState({
      editTeamDialogStatus: true,
      teamInEdit: team
    });
  };

  handleSelectGame(game) {
    this.props.history.push(Routes.PLAY + "/" + game.gameId);
  }

  handleCloseActiveGamesDialog() {
    this.setState({ activeGamesDialogIsOpen: false });
  };

  handleShowSelectEvents() {

    var teams = this.state.teams

    if (this.state.teams.length === 0) {
      this.toggleAlertDialog('Klicka på knappen "Skapa nytt sällskap / lag" för att skapa en turnering.');
      return;
    }

    var teamUnderMinimumPlayers = find(teams, function (t) { return t.players.length < 2 });

    if (typeof teamUnderMinimumPlayers !== "undefined") {
      if (teams.length > 1) {
        this.toggleAlertDialog("Lägg till minst 2 spelare per lag innan du kan gå vidare.");
      } else {
        this.toggleAlertDialog("Lägg till minst 2 spelare till sällskapet innan du kan gå vidare.");
      }
      return;
    }

    this.setState({
      showSelectEvents: true,
      showStartDialog: false,
      showTeamItems: false,
    });
  }

  handleGoBack() {
    this.setState({
      showSelectEvents: false,
      showStartDialog: true,
      showTeamItems: true
    });
  }

  handleOpenActiveGamesDialog() {
    this.props.history.push({ pathname: '/activeteams' });
  }

  getClassNamesForAddTeamButton(numberOfTeams) {

    const { classes } = this.props;

    if (numberOfTeams >= 1) {
      return classes.button + ' buttonAddMultipleTeams';
    } else {
      return classes.buttonColors + ' ' + classes.button;
    }
  }

  getEventCategoryDescription(point) {

    switch (point.calculationFormat) {
      case constants.calculationFormats.time.value:
        if (point.calculationCategory === constants.calculationCategories.lowest.value) {
          return 'Lägsta tid';
        } else {
          return 'Högsta tid';
        }
      default:
        if (point.calculationCategory === constants.calculationCategories.closest.value) {
          return 'Närmast poäng';
        } else if (point.calculationCategory === constants.calculationCategories.lowest.value) {
          return 'Lägsta poäng';
        }
        return 'Högsta poäng';
    }
  }

  static contextType = AuthContext;
  render() {
    const { classes } = this.props;
    const selectEvents =
      <Zoom in={this.state.showSelectEvents}>
        <Grid container spacing={24}>
          <Grid item xs={12} sm={12} className={classes.gridItem}>
            <Card className={classes.cardTeam}>
              <CardContent>
                <Typography variant="subtitle1" gutterBottom className={classes.TypographyTeamName}>
                  Välj grenar:
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                </Typography>

                <List>
                  {this.state.points.map(point =>
                    <ListItem key={point.eventId} role={undefined} dense button onClick={this.handleToggleEvents.bind(this, point)}>
                      <Checkbox
                        checked={this.state.checkedPoints.indexOf(point) !== -1}
                        tabIndex={-1}
                        disableRipple
                      />
                      <ListItemText primary={point.eventName + ' - ' + this.getEventCategoryDescription(point) + ` (${typeof point.unit === "undefined" ? 'p' : point.unit})`} />
                    </ListItem>
                  )}
                </List>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.gridItem}>
            <Button
              className={classes.buttonColors + ' ' + classes.button}
              onClick={this.handleGoBack.bind(this)}>
              <div style={{ padding: 20 }}>
                <IoIosArrowDropleft style={{ fontSize: 55 }} />
                <Typography className={classes.buttonText}>
                  Gå tillbaka
                </Typography>
              </div>
            </Button>
          </Grid>
          <Grid item xs={12} sm={8} className={classes.gridItem}>
            <Button
              className={classes.buttonColors + ' ' + classes.button}
              onClick={this.handleStartGame}>
              <div style={{ padding: 20 }}>
                <IoIosCheckmarkCircleOutline style={{ fontSize: 55 }} />
                <Typography className={classes.buttonText}>
                  Starta turnering
                </Typography>
              </div>
            </Button>
          </Grid>
        </Grid>
      </Zoom>


    const teamItems = this.state.teams.map((team, index) =>
      <Zoom key={team.id} in={true} style={{ transitionDelay: this.state.zoomIn ? 100 : 0 }}>
        <Grid container spacing={24}>
          <Grid item xs={12} sm={12} className={classes.gridItem}>
            <Card className={classes.cardTeam}>
              <CardContent>
                <div>
                  <Typography style={{ display: 'inline-block' }} variant="subtitle1" color="primary" className={classes.TypographyTeamName}> 
                    {team.name}
                    <MdEdit onClick={() => this.handleOpenEditTeamDialog(team)} size={20} style={{ color: "grey", marginLeft: 5, verticalAlign: "initial", cursor: "pointer" }} />
                  </Typography>
                  <IconButton align="right" style={{ float: 'right' }} aria-label="Refresh" onClick={() => this.showDeleteTeamDialog(index)}>
                    <TiTimes style={{ fontSize: 30, color: '#989898' }} />
                  </IconButton>
                </div>

                {team.players.map(player => {
                  let avatar = null;
                  return (
                    <Chip
                      key={player.id}
                      avatar={avatar}
                      label={<span>
                        <MdEdit onClick={() => this.handleOpenEditPlayerDialog(player)} size={20} style={{ color: "grey", marginRight: 5, verticalAlign: "middle", cursor: "pointer" }} />
                        {player.name}
                      </span>}
                      onDelete={() => this.handleDeletePlayer(player)}
                      className={classes.chip}
                    />
                  );
                })}

              </CardContent>
              <CardActions>
                <NewPlayerInput
                  onAddPlayer={this.handleAddPlayer}
                  teamIndex={index}
                />
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Zoom>
    );

    const startDialog =
      <Zoom in={this.state.showStartDialog}>
        <Grid container spacing={24}>
          <Grid item xs={12} sm={4} className={classes.gridItem}>
            <Button
              variant="outlined"
              className={this.getClassNamesForAddTeamButton(this.state.teams.length)}
              onClick={this.handleOpenTeamDialog}>
              <div style={{ padding: 20 }}>
                {this.state.teams.length > 0 ? <React.Fragment>
                  <MdGroupAdd className={'mdGroupAdd'} style={{ fontSize: 55 }} />
                  <Typography className={'buttonAddTeamText'}>Lägg till flera lag</Typography></React.Fragment>
                  : <React.Fragment><MdGroupAdd style={{ fontSize: 55 }} /><Typography className={classes.buttonText}>Skapa nytt sällskap / lag</Typography></React.Fragment>
                }
              </div>
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.gridItem}>
            <Button
              variant="outlined"
              className={classes.buttonColors + ' ' + classes.button}
              onClick={this.handleOpenActiveGamesDialog}>
              <div style={{ padding: 20 }}>
                <MdMoreHoriz style={{ fontSize: 55 }} />
                <Typography className={classes.buttonText}>Pågående turneringar</Typography>
              </div>
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.gridItem}>
            <Button
              variant="outlined"
              className={classes.buttonColors + ' ' + classes.button}
              onClick={this.handleShowSelectEvents}>
              <div style={{ padding: 20 }}>
                <IoIosArrowForward style={{ fontSize: 55 }} />
                <Typography className={classes.buttonText}>Gå vidare</Typography>
              </div>
            </Button>
          </Grid>
        </Grid>
      </Zoom>

      const otherInfoArea =
      <Zoom key={987654} in={true} style={{ transitionDelay: this.state.zoomIn ? 100 : 0 }}>
        <Grid container spacing={24}>
          <Grid item xs={12} sm={12} className={classes.gridItem}>
            <OtherInfoInput onOtherInfoChange={this.handleOtherInfoChange}/>
          </Grid>
        </Grid>
      </Zoom>

    return (
      <div className={classes.root}>

        {this.state.showTeamItems && teamItems}
        {this.state.teams.length > 0 && !this.state.showSelectEvents && otherInfoArea}
        {this.state.showStartDialog && startDialog}
        {this.state.showSelectEvents && selectEvents}

        <AlertDialog
          open={this.state.alertDialogIsOpen}
          title={'Meddelande'}
          body={this.state.dialogBody}
          toggleAlertDialog={this.toggleAlertDialog} />

        <ConfirmDialog
          open={this.state.deleteTeamDialogIsOpen}
          title={'Ta bort lag'}
          body={'Vill du verkligen ta bort laget?'}
          toggleDialog={this.toggleDeleteTeamDialog}
          confirm={this.handleDeleteTeam} />

        <CreateItemDialog
          dialogStatus={this.state.addTeamDialogStatus}
          dialogTitle={this.state.teams.length < 1 ? "Skapa sällskap / lag" : "Skapa lag"}
          dialogDesc={this.state.teams.length < 1 ?
            <span style={{ fontSize: 14 }}>Skriv namn på sällskapet alternativt lagets namn om flera lag tävlar mot varandra</span> :
            <span style={{ fontSize: 14 }}>Skriv namn på lag nummer {this.state.teams.length + 1}</span>
          }
          dialogLabel={this.state.teams.length < 1 ? 'Sällskapet / lagets namn:' : 'Lagets namn:'}
          onInputChange={this.handleTeamNameChange}
          defaultValue={''}
          onClose={this.handleCloseTeamDialog}
          onSubmit={this.handleAddTeam}
          addLabel="Skapa sällskap / lag" />

        <EditPlayerDialog
          dialogStatus={this.state.editPlayerDialogStatus}
          dialogTitle={"Ändra namn på spelare"}
          dialogDesc={<span style={{ fontSize: 14 }}>Skriv namn på spelaren</span>
          }
          dialogLabel={'Spelarens namn:'}
          onInputChange={this.handlePlayerNameChange}
          defaultValue={this.state.playerInEdit === null ? '' : this.state.playerInEdit.name}
          onClose={this.handleCloseEditPlayerDialog}
          onSubmit={this.handleUpdatePlayer}
          addLabel="Spara" />

        <EditTeamDialog
          dialogStatus={this.state.editTeamDialogStatus}
          dialogTitle={"Ändra namn på laget"}
          dialogDesc={<span style={{ fontSize: 14 }}>Skriv namn på laget</span>
          }
          dialogLabel={'Lagets namn:'}
          onInputChange={this.handleTeamNameUpdateChange}
          defaultValue={this.state.teamInEdit === null ? '' : this.state.teamInEdit.name}
          onClose={this.handleCloseEditTeamDialog}
          onSubmit={this.handleUpdateTeam}
          addLabel="Spara" />

        <ActiveGamesDialog
          activeGamesDialogIsOpen={this.state.activeGamesDialogIsOpen}
          selectGame={this.handleSelectGame}
          games={this.state.games}
          onClose={this.handleCloseActiveGamesDialog}
        />
      </div>
    );
  }
}

Play.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Play);
