import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { db } from '../firebase/firebase';
import { AuthContext } from '../firebase/authContext';
import AdminTabs from '../components/AdminTabs';
import ProgressIndicator from '../components/ProgressIndicator';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EnhancedTableHead from '../components/EnhancedTableHead';
import { getSorting, stableSort } from '../common/helpers';
import Moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateEventDialog from '../components/CreateEventDialog';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import StarIcon from '@material-ui/icons/Stars';
import RoundIcon from '@material-ui/icons/PanoramaFishEye';
import * as constants from "../common/constants";

const rows = [

  { id: 'name', numeric: false, disablePadding: true, label: 'Namn' },
  { id: 'format', numeric: false, disablePadding: true, label: 'Format' },
  { id: 'category', numeric: false, disablePadding: true, label: 'Kategori' },
  { id: 'placementCalculation', numeric: false, disablePadding: true, label: 'Placeringsberäkning' },
  { id: 'unit', numeric: false, disablePadding: true, label: 'Enhet' },
  { id: 'created', numeric: false, disablePadding: true, label: 'Skapad' },
  { id: 'dividingEvent', numeric: true, disablePadding: true, label: 'Skiljegren' },
  { id: 'delete', numeric: true, disablePadding: true, label: 'Ta bort' },

];

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: 10,
    marginLeft: 15,
    marginRight: 15,
    marginTop: 15,
    marginBottom: 15
  },
  table: {
    minWidth: 400,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

class AdminEvents extends React.Component {

  constructor() {
    super()

    this.toggleCreateEventDialog = this.toggleCreateEventDialog.bind(this);
    this.saveEvent = this.saveEvent.bind(this);


    this.state = {
      order: 'asc',
      orderBy: 'name',
      isLoading: true,
      events: null,
      page: 0,
      rowsPerPage: 10,
      createEventDialogIsOpen: false,
      newEvent: {
        name: "",
        created: null
      }
    };
  }

  setDividingEvent(event) {

    this.setState({ isLoading: true });

    var newValue = !event.isDividingEvent;

    let self = this;
    var events = Object.assign([], this.state.events);
    let customerId = this.context.customer.id;
    var batch = db.batch();
    var eventRef = null;
    events.map(function (x) {
      if (x.id === event.id) {
        eventRef = db.collection("customers").doc(customerId).collection('events').doc(x.id);
        x.isDividingEvent = newValue;
        batch.update(eventRef, { "isDividingEvent": newValue });
        return x;
      }
      else {
        eventRef = db.collection("customers").doc(customerId).collection('events').doc(x.id);
        x.isDividingEvent = false;
        batch.update(eventRef, { "isDividingEvent": false });
        return x;
      }
    });

    batch.commit().then(function (docRef) {
      self.fetchEvents();
    })
      .catch(function (error) {
        console.error("Error: ", error);
      });
  }

  saveEvent(newEvent) {

    this.setState({ isLoading: true });
    let self = this;
 
    let customerId = this.context.customer.id;

    db.collection("customers").doc(customerId).collection('events').add(newEvent)
      .then(function (docRef) {
        self.fetchEvents();
        self.toggleCreateEventDialog();
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      });

  }

  deleteEvent(id) {

    this.setState({ isLoading: true });

    let self = this;

    let customerId = this.context.customer.id;
    db.collection("customers").doc(customerId).collection('events').doc(id).delete().then(function () {
      self.fetchEvents();
    }).catch(function (error) {
      console.error("Error removing event: ", error);
    });

  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  componentDidMount() {
    this.fetchEvents();
  }

  fetchEvents() {

    var events = [];

    let customerId = this.context.customer.id;

    db.collection("customers").doc(customerId).collection('events').get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        var eventObj = {
          id: doc.id,
          name: doc.data().name,
          calculationFormat: doc.data().calculationFormat,
          unit: doc.data().unit,
          calculationCategory: doc.data().calculationCategory,
          placementCalculation: doc.data().placementCalculation,
          created: doc.data().created.toDate(),
          isDividingEvent: doc.data().isDividingEvent,
          roundPoints: doc.data().roundPoints
        }
        console.log(eventObj);
        events.push(eventObj);
      });

      this.setState({
        events: events,
        isLoading: false
      });
    });

  }

  toggleCreateEventDialog() {
    this.setState({ createEventDialogIsOpen: !this.state.createEventDialogIsOpen });
  };

  getCategory(event) {

    if (event.calculationCategory === constants.calculationCategories.highest.value) {
      return <span>Högsta</span>
    } else if (event.calculationCategory === constants.calculationCategories.lowest.value) {
      return <span>Lägsta</span>
    }
    else if (event.calculationCategory === constants.calculationCategories.closest.value) {
      return <span>Närmast</span>
    }
  }

  getPlacementCalculationDesc(event) {

    if (event.placementCalculation === constants.placementCalculation.following.value) {
      return <span>Direkt efterföljande</span>
    }
    return <span>Default</span>
  }

  getFormat(event) {

    if (event.calculationFormat === constants.calculationFormats.time.value) {
      return <span>Tid</span>
    }
    else {
      return <span>Poäng</span>
    }
  }

  static contextType = AuthContext;
  render() {

    const { classes } = this.props;
    const { events, order, orderBy, rowsPerPage, page, isLoading } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, (events === null ? -1 : events.length) - page * rowsPerPage);

    if (isLoading === false) {

      return (
        <React.Fragment>

          <AdminTabs activeTabIndex={0} />

          <CreateEventDialog
            dialogStatus={this.state.createEventDialogIsOpen}
            dialogTitle="Skapa gren"
            dialogDesc="Skriv namn på grenen&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
            dialogLabel="Ange grenens namn:"
            onClose={this.toggleCreateEventDialog}
            onAdd={this.saveEvent}
            addLabel="Skapa gren" />


          <Paper className={classes.root}>

            <div style={{ display: 'flex' }}>
              <Typography variant="h6" gutterBottom>
                Grenar
              </Typography>
              <div style={{ flexGrow: 1 }} />
              <Button color="primary" onClick={this.toggleCreateEventDialog}>
                + Skapa ny gren
              </Button>
            </div>

            <div className={classes.tableWrapper}>
              <Table className={classes.table} aria-labelledby="tableTitle">
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={this.handleRequestSort}
                  rowCount={events.length}
                  rows={rows}
                />
                <TableBody>
                  {stableSort(events, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(e => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={e.id}
                        >
                          <TableCell component="th" scope="row">
                            {e.name}
                          </TableCell>

                          <TableCell>
                            {this.getFormat(e)}
                          </TableCell>

                          <TableCell>
                            {this.getCategory(e)}
                          </TableCell>

                          <TableCell>
                            {this.getPlacementCalculationDesc(e)}
                          </TableCell>

                          <TableCell>
                            {typeof e.unit === "undefined" ? 'p' : e.unit}
                          </TableCell>
                          
                          <TableCell>{Moment(e.created).format('YYYY-MM-DD HH:mm:ss')}</TableCell>

                          <TableCell align="right">
                            <IconButton onClick={() => this.setDividingEvent(e)}>
                              {e.isDividingEvent ? <StarIcon style={{ color: '#f4c20d' }} /> : <RoundIcon style={{ fontSize: 24 }} />}
                            </IconButton>
                          </TableCell>

                          <TableCell align="right">
                            <IconButton onClick={() => this.deleteEvent(e.id)}>
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 49 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={events.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Paper>



        </React.Fragment>
      );
    }
    return (<ProgressIndicator />)
  }
}

export default withStyles(styles)(AdminEvents);
