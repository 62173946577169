import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import * as constants from "../common/constants";
import { getHundreths, getSeconds, getMinutes, formatMinutes, formatSeconds, formatHundreths } from '../common/helpers';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  paper: {
    backgroundColor: 'blue'
  },
  button: {
    fontSize: '1.6em',
    //minHeight: 70,
    color: '#0074D9',
    margin: 'auto',
    width: '100%',
    height: '100%',
    borderRadius: 0,
    backgroundColor: '#FFF',

    /*  '&:hover': {
       backgroundColor: '#00d679',
     },
     '&:active': {
       backgroundColor: '#00d679',
     } */
  },
  okButton: {
    margin: theme.spacing.unit,
    color: 'white'
  },
  input: {
    display: 'none',
  },
  pointsContainer: {
    borderTop: '1px solid #ff5960',
    borderBottom: '1px solid #ff5960',
    marginTop: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '8vh'
  },
  chip: {
    marginRight: theme.spacing.unit / 2,
    marginTop: theme.spacing.unit / 2,
    backgroundColor: '#e6e6e6',
  },
});

//Byter ut en character mot en annan i en sträng.
String.prototype.replaceAt = function (index, replacement) {
  return this.substr(0, index) + replacement + this.substr(index + replacement.length);
}

class KeypadDialog extends React.Component {

  constructor(props) {
    super(props);
    this.handleKeypadInput = this.handleKeypadInput.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onKeyPressed = this.onKeyPressed.bind(this);
    this.savePoints = this.savePoints.bind(this);
    this.getTotalRoundPoints = this.getTotalRoundPoints.bind(this);
    this.handleDeleteRoundPoint = this.handleDeleteRoundPoint.bind(this);
    this.isTimeEvent = this.isTimeEvent.bind(this);


    this.state = {
      points: '0',
      roundPoints: [],
      newRoundPoints: '0',
      timeUnitInEdit: constants.timeUnits.minutes.value,
      characterIndexInEdit: 0
    };
  }

  handleClose() {
    this.props.onClose();
    this.setState({
      points: 0,
      newRoundPoints: 0,
      roundPoints: [],
      timeUnitInEdit: constants.timeUnits.minutes.value,
      characterIndexInEdit: 0
    });
  }

  savePoints() {

    var points = this.state.points;
    var roundPoints = Object.assign([], this.state.roundPoints);

    if (this.props.keypad.isEditingClosestTo) {
      this.props.saveEventClosestTo(this.props.keypad, points);
    }
    else {
      if (roundPoints.length > 0) {
        var x = roundPoints.reduce(this.sumArray);
        this.props.savePoints(this.props.keypad, x, roundPoints);
      } else {
        this.props.savePoints(this.props.keypad, points, roundPoints);
      }
    }

    this.setState({
      points: 0,
      newRoundPoints: 0,
      roundPoints: [],
      timeUnitInEdit: constants.timeUnits.minutes.value,
      characterIndexInEdit: 0
    });
  }

  handleKeypadInputForTimeFormat(value, points) {

    //Om användaren rensar eller trycker på backspace så rensar vi allt och sätter markören på minuter.
    if (value === "rensa" || value === "backspace") {
      this.setState({
        points: 0,
        characterIndexInEdit: 0,
        timeUnitInEdit: constants.timeUnits.minutes.value
      });
      return;
    }

    if (this.state.timeUnitInEdit === constants.timeUnits.minutes.value) {

      let newPoints = formatMinutes(value, points, this.state.characterIndexInEdit);

      //Om användaren anger siffra två i minuter så navigerar vi till hundradelarna.
      let nextTimeUnitToEdit = newPoints === null ? constants.timeUnits.minutes.value : constants.timeUnits.seconds.value;
      let nextCharacterIndexInEdit = newPoints === null ? 0 : 1;

      this.setState({
        points: newPoints,
        timeUnitInEdit: nextTimeUnitToEdit,
        characterIndexInEdit: nextCharacterIndexInEdit
      });
    }

    //Vid editering på sekunder.
    else if (this.state.timeUnitInEdit === constants.timeUnits.seconds.value) {

      let newPoints = formatSeconds(value, points, this.state.characterIndexInEdit);
      let characterInEditState = this.state.characterIndexInEdit;

      let characterIndexInEdit = 1;
      if (this.state.characterIndexInEdit > 0) {
        characterIndexInEdit = --characterInEditState;
      }

      let nextTimeUnitToEdit = constants.timeUnits.seconds.value;
      if (newPoints !== null) {
        //Om användaren anger siffra två i minuter så navigerar vi till hundradelarna.
        nextTimeUnitToEdit = this.state.characterIndexInEdit === 0 ? constants.timeUnits.hundredths.value : constants.timeUnits.seconds.value;
        if (nextTimeUnitToEdit === constants.timeUnits.hundredths.value) {
          characterIndexInEdit = 2;
        }
      }

      //Om newPoints är null så har användaren angivit högre än 59 minuter. Vi sätter då newPoints till nuvarande points.
      //Så att användaren har chans att ändra sitt misstag innan vi navigerar till hundradelarna.
      if (newPoints === null) {
        newPoints = points;
      }

      this.setState({
        points: newPoints,
        characterIndexInEdit: characterIndexInEdit,
        timeUnitInEdit: nextTimeUnitToEdit,
      });
    }

    else if (this.state.timeUnitInEdit === constants.timeUnits.hundredths.value) {

      let newPoints = formatHundreths(value, points, this.state.characterIndexInEdit);

      let characterInEditState = this.state.characterIndexInEdit;

      let characterIndexInEdit = 2;
      //Om newPoints är null så har användaren efter att denna angett 3st av samma siffra och sedan angett samma siffra en fjärde gång.
      //Vi ändrar då inte characterIndexInEdit. 
      if (newPoints !== null) {
        if (this.state.characterIndexInEdit > 0) {
          characterIndexInEdit = --characterInEditState;
        }
      }

      //Om newPoints är null så har användaren efter att denna angett 3st av samma siffra och sedan angett samma siffra en fjärde gång.
      if (newPoints === null) {
        newPoints = points;
      }

      this.setState({
        points: newPoints,
        characterIndexInEdit: characterIndexInEdit
      });
    }
  }

  isTimeEvent() {
    if (typeof this.props.keypad.calculationFormat !== 'undefined' && this.props.keypad.calculationFormat === constants.calculationFormats.time.value) {
      return true;
    }
    return false;
  }


  handleKeypadInput(e) {

    let value = '0';

    if (typeof e.currentTarget === 'undefined') {
      value = e;
    }
    else {
      value = e.currentTarget.value;
    }

    let points = this.state.points;

    if (this.props.keypadInEditMode) {
      
      if (this.props.keypad.points === null) {
        points = '0';
      } else if (value === '.') {
        points = '0.'
      } else {
        points = this.props.keypad.points;
      }

      this.props.setKeypadInEditToFalse();
    }

    if (this.isTimeEvent()) {
      this.handleKeypadInputForTimeFormat(value, points);
      return;
    }

    if (e === 'backspace') {

      //Agera inte om värdet redan är null.
      if (points === null) {
        return;
      }

      //Konverterar poängen till en sträng för enklare hantering av strängen.
      points = points.toString();

      //Skriv en nolla om användaren raderar sista siffran.
      if (points.length === 1) {
        points = '0';
        this.setState({
          points: points
        });
        return;
      }

      //Ta bort sista bokstaven/siffran i poängen.
      points = points.substring(0, points.length - 1);

      this.setState({
        points: points
      });
      return;
    }

    if (value === 'rensa') {
      this.setState({
        points: '0'
      });
      return;
    }

    if (points === '0') {
      this.setState({
        points: value
      });
      return;
    }

    if (value === '.') {

      //If the string already contains a dot. We do nothing.
      if (this.state.points.toString().includes(".")) {
        return;
      }

      this.setState({
        points: this.state.points + '.'
      });
      return;
    }

    if (value === '+') {

      const p = parseFloat(points);
      var rPoints = Object.assign([], this.state.roundPoints);
      rPoints.push(p);

      this.setState({
        roundPoints: rPoints,
        newRoundPoints: 0,
        points: 0
      });
      return;
    }

    var newPoints = 0;

    //Om det är en redigering av poäng så radera den befintliga poängen i som står i keypaden 
    //när användaren trycker på en siffertangent för första gången. Så slipper användaren trycka på rensa-knappen först.
    if (this.props.keypadInEditMode) {
      newPoints = value;
    }
    else {
      newPoints = points + value;
    }

    //If the points contains a dot. We let the points be max 8 characters
    if (newPoints.includes(".") && newPoints.length > 8) {
      return;
    }

    //If the points contains a dot. We let the points be max 7 characters
    if (!newPoints.includes(".") && newPoints.length > 7) {
      return;
    }

    newPoints = this.tryParse(newPoints);

    this.setState({
      points: newPoints,
      newRoundPoints: newPoints
    });

  }

  navigateInTimeUnits(direction) {
    if (direction === constants.timeUnitDirections.right.value && this.state.timeUnitInEdit === constants.timeUnits.minutes.value) {
      this.setState({ timeUnitInEdit: constants.timeUnits.seconds.value, characterIndexInEdit: 1 });
    }
    if (direction === constants.timeUnitDirections.right.value && this.state.timeUnitInEdit === constants.timeUnits.seconds.value) {
      this.setState({ timeUnitInEdit: constants.timeUnits.hundredths.value, characterIndexInEdit: 2 });
    }
    if (direction === constants.timeUnitDirections.left.value && this.state.timeUnitInEdit === constants.timeUnits.hundredths.value) {
      this.setState({ timeUnitInEdit: constants.timeUnits.seconds.value, characterIndexInEdit: 1 });
    }
    if (direction === constants.timeUnitDirections.left.value && this.state.timeUnitInEdit === constants.timeUnits.seconds.value) {
      this.setState({ timeUnitInEdit: constants.timeUnits.minutes.value, characterIndexInEdit: 0 });
    }
  }

  handleTimeUnitClick(timeUnit) {
    if (timeUnit === constants.timeUnits.minutes.value) {
      this.setState({ timeUnitInEdit: constants.timeUnits.minutes.value, characterIndexInEdit: 0 });
    } else if (timeUnit === constants.timeUnits.seconds.value) {
      this.setState({ timeUnitInEdit: constants.timeUnits.seconds.value, characterIndexInEdit: 1 });
    } else if (timeUnit === constants.timeUnits.hundredths.value) {
      this.setState({ timeUnitInEdit: constants.timeUnits.hundredths.value, characterIndexInEdit: 2 });
    }
  }

  renderKeypadContent(props) {

    const unit = typeof props.keypad.unit === "undefined" ? 'p' : props.keypad.unit;

    //keypadInEditMode är true om användaren tryckt på redigera-pennan ikonen.
    if (props.keypadInEditMode) {
      if (this.isTimeEvent()) {
        return this.renderTime();
      }
      if (props.keypad.points === null) {
        return `0${unit}`;
      } else {
        return props.keypad.points.toString() + `${unit}`;
      }
    } else {
      if (this.isTimeEvent()) {
        return this.renderTime();
      }
      if (this.state.points === null) {
        return `0${unit}`;
      } else {
        return this.state.points.toString() + `${unit}`;
      }
    }
  }

  renderTime() {

    let minutes = getMinutes(this.state.points);
    let seconds = getSeconds(this.state.points);
    let hundredths = getHundreths(this.state.points);
    return <span>
      <span onClick={() => this.handleTimeUnitClick(constants.timeUnits.minutes.value)}
        style={{
          cursor: "pointer",
          textDecoration: this.state.timeUnitInEdit === constants.timeUnits.minutes.value ? 'underline' : 'none'
        }}>
        {minutes}
      </span>
      :
      <span onClick={() => this.handleTimeUnitClick(constants.timeUnits.seconds.value)}
        style={{ cursor: "pointer" }}>
        {seconds.charAt(0)}
        <span style={{ textDecoration: this.state.timeUnitInEdit === constants.timeUnits.seconds.value ? 'underline' : 'none' }}>
          {seconds.charAt(1)}
        </span>
      </span>.
      <span onClick={() => this.handleTimeUnitClick(constants.timeUnits.hundredths.value)}
        style={{ cursor: "pointer" }}>
        {hundredths.charAt(0)}
        {hundredths.charAt(1)}
        <span style={{ textDecoration: this.state.timeUnitInEdit === constants.timeUnits.hundredths.value ? 'underline' : 'none' }}>
          {hundredths.charAt(2)}
        </span>
      </span>
    </span>
  }

  tryParse(x) {
    var parsed = parseFloat(x);
    if (isNaN(parsed)) { return null }
    return parsed;
  }

  onKeyPressed(e) {

    switch (e.keyCode) {
      case 8:
        this.handleKeypadInput('backspace');
        break;
      case 48:
      case 96:
        this.handleKeypadInput('0');
        break;
      case 49:
      case 97:
        this.handleKeypadInput('1');
        break;
      case 50:
      case 98:
        this.handleKeypadInput('2');
        break;
      case 51:
      case 99:
        this.handleKeypadInput('3');
        break;
      case 52:
      case 100:
        this.handleKeypadInput('4');
        break;
      case 53:
      case 101:
        this.handleKeypadInput('5');
        break;
      case 54:
      case 102:
        this.handleKeypadInput('6');
        break;
      case 55:
      case 103:
        this.handleKeypadInput('7');
        break;
      case 56:
      case 104:
        this.handleKeypadInput('8');
        break;
      case 57:
      case 105:
        this.handleKeypadInput('9');
        break;
      case 110:
      case 110:
        this.handleKeypadInput('.');
        break;
      case 39:
        this.navigateInTimeUnits(constants.timeUnitDirections.right.value)
        break;
      case 37:
        this.navigateInTimeUnits(constants.timeUnitDirections.left.value)
        break;
      case 13:
        setTimeout(() => {
          this.savePoints();
        }, 50);
        break;
      case 27:
        this.handleClose();
        break;
      default:
        return;
    }
  }

  onEnter = (e) => {

    const points = this.props.keypad.points;
    const roundPoints = this.props.keypad.roundPoints;

    if (points !== null) {
      this.setState({
        points: points,
        roundPoints: roundPoints,
        newRoundPoints: 0
      });
    }
  }

  sumArray = (total, num) => {
    return total + num;
  }

  getTotalRoundPoints() {

    if (this.state.roundPoints.length > 0) {
      var x = this.state.roundPoints.reduce(this.sumArray);
      return x;
    }
    return 0;
  }

  handleDeleteRoundPoint(index) {
    var rPoints = Object.assign([], this.state.roundPoints);

    rPoints.splice(index, 1);

    this.setState({
      roundPoints: rPoints,
    });
  }

  render() {

    const { classes, keypad } = this.props;

    return (
      <Dialog
        onEnter={this.onEnter}
        maxWidth={"xs"}
        fullWidth={true}
        open={keypad.dialogStatus}
        onClose={this.handleClose}
        onKeyDown={this.onKeyPressed}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            backgroundColor: '#F8F8F8',
            maxWidth: '400px'
          }
        }}
      >

        <DialogContent>
          <Typography variant="h6">{keypad.title}
          </Typography>

          {this.state.roundPoints.length > 0 &&
            this.state.roundPoints.map((rPoint, index) => {
              return (
                <Chip
                  key={index}
                  avatar={null}
                  label={rPoint}
                  value={index}
                  onDelete={() => this.handleDeleteRoundPoint(index)}
                  className={classes.chip}
                />
              );
            })}

          <Grid container className={classes.pointsContainer}>
            <Grid item xs={12}>
              {this.state.roundPoints.length > 0 ?
                <div>
                  <Typography style={{ fontSize: '1.3em', float: 'left' }}>
                    {this.props.keypadInEditMode ?
                      (keypad.points === null ? '0p' : keypad.points.toString() + 'p') :
                      (this.state.points === null ? '0p' : this.state.points.toString() + 'p')
                    }
                  </Typography>

                  <Typography style={{ fontSize: '1.3em', float: 'right' }}>
                    {'Totalt: ' + this.getTotalRoundPoints() + `${typeof keypad.unit === "undefined" ? 'p' : keypad.unit}`}
                  </Typography>

                </div>
                :
                <Typography variant="h5" align="center">
                  {this.renderKeypadContent(this.props)}
                </Typography>
              }
            </Grid>
          </Grid>
          <br />
          <Grid container style={{ borderColor: '#D8D8D8', borderStyle: 'solid solid none solid', borderWidth: '1px', height: '10vh' }}>
            <Grid item xs={4} style={{ borderRight: '1px solid #D8D8D8', borderWidth: '1px' }}>
              <Button
                color="primary"
                className={classes.button}
                value="7"
                onClick={this.handleKeypadInput}>
                7
              </Button>
            </Grid>
            <Grid item xs={4} style={{ borderRight: '1px solid #D8D8D8', borderWidth: '1px' }}>
              <Button
                color="primary"
                className={classes.button}
                value="8"
                onClick={this.handleKeypadInput}>
                8
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                color="primary"
                className={classes.button}
                value="9"
                onClick={this.handleKeypadInput}>
                9
              </Button>
            </Grid>
          </Grid>

          <Grid container style={{ borderColor: '#D8D8D8', borderStyle: 'solid solid none solid', borderWidth: '1px', height: '10vh' }}>
            <Grid item xs={4} style={{ borderRight: '1px solid #D8D8D8', borderWidth: '1px' }}>
              <Button
                color="primary"
                className={classes.button}
                value="4"
                onClick={this.handleKeypadInput}>
                4
              </Button>
            </Grid>
            <Grid item xs={4} style={{ borderRight: '1px solid #D8D8D8', borderWidth: '1px' }}>
              <Button
                color="primary"
                className={classes.button}
                value="5"
                onClick={this.handleKeypadInput}>
                5
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                color="primary"
                className={classes.button}
                value="6"
                onClick={this.handleKeypadInput}>
                6
              </Button>
            </Grid>
          </Grid>
          <Grid container style={{ borderColor: '#D8D8D8', borderStyle: 'solid solid none solid', borderWidth: '1px', height: '10vh' }}>
            <Grid item xs={4} style={{ borderRight: '1px solid #D8D8D8', borderWidth: '1px' }}>
              <Button
                color="primary"
                className={classes.button}
                value="1"
                onClick={this.handleKeypadInput}>
                1
              </Button>
            </Grid>
            <Grid item xs={4} style={{ borderRight: '1px solid #D8D8D8', borderWidth: '1px' }}>
              <Button
                color="primary"
                className={classes.button}
                value="2"
                onClick={this.handleKeypadInput}>
                2
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                color="primary"
                className={classes.button}
                value="3"
                onClick={this.handleKeypadInput}>
                3
              </Button>
            </Grid>
          </Grid>

          <Grid container style={{ borderColor: '#D8D8D8', borderStyle: 'solid solid solid solid', borderWidth: '1px', height: '10vh' }}>
            <Grid item xs={3} style={{ borderColor: '#D8D8D8', borderStyle: 'none solid none none', borderWidth: '1px' }}>
              <Button
                color="primary"
                className={classes.button}
                value="0"
                onClick={this.handleKeypadInput}>
                0
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                color="primary"
                className={classes.button}
                value="+"
                onClick={this.handleKeypadInput}
                disabled={this.isTimeEvent()}>
                +
              </Button>
            </Grid>
            <Grid item xs={3} style={{ borderColor: '#D8D8D8', borderStyle: 'none none none solid', borderWidth: '1px' }}>
              <Button
                color="primary"
                className={classes.button}
                value="."
                onClick={this.handleKeypadInput}>
                ,
              </Button>
            </Grid>
            <Grid item xs={3} style={{ borderColor: '#D8D8D8', borderStyle: 'none none none solid', borderWidth: '1px' }}>
              <Button
                color="primary"
                className={classes.button}
                value="rensa"
                onClick={this.handleKeypadInput}>
                CE
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Avbryt
          </Button>
          <Button onClick={this.savePoints} variant="contained" size="large" color="primary" className={classes.okButton}>
            Spara
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(KeypadDialog);